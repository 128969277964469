.welcome_message {
  height: 30px;
}

.welcome_message_title {
  display: inline;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.welcome_message_description {
  display: inline;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word; /* Important for long words! */
}

.video_welcome_like {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 5px;
  -webkit-justify-content: flex-end;
}

.welcome_modal .ant-modal-footer {
  display: flex;
  justify-content: end;
  padding: 2px;
  -webkit-justify-content: flex-end;
}

.welcome_modal .ant-modal-body {
  padding: 10px;
  height: 81vh;
}

.welcome_image_container {
  width: 100% !important;
  height: 84% !important; 
  object-fit: contain !important;
}

@media (max-width: 900px) {
  .welcome_message_description {
    -webkit-line-clamp: 5;
  }
  .welcome_modal .ant-modal-body {
    height: 50vh;
    margin-top: 130px;
  }
  .react-player-class {
    height: 65% !important;
  }
}

@media (max-width: 500px) {
  .welcome_modal .ant-modal-body {
    margin-top: 0px !important;
    height: 81vh !important;
  }
}
