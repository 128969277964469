.brand_zone_img_container {
  width: 340px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
}

.ms_page_container {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  padding: 12px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: flex;
  column-gap: 30px;
}

.ms_img_container {
  width: auto;
  height: auto;
  max-width: 400px;
  max-height: 300px;
  border: 1px solid #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.ms_zero_hero {
  color: #999999;
}

.ms_page_content {
  width: 100%;
}

.ms_page_title {
  font-size: 18px;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

.ms_page_description {
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.ms_interact_live {
  font: normal normal medium 18px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.ms_btns_container {
  display: flex;
  width: 100%;
  column-gap: 10px;
  margin-bottom: 5px;
}

.navigations_zone_card_btns {
  display: flex;
  flex-wrap: wrap;

  gap: 8px;
}

.ms_btn {
  display: flex;
  column-gap: 5px;
  align-items: center;
  margin-bottom: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  letter-spacing: 0.8px;
  color: #ef0855;
  opacity: 1;
}

.ms_external_links {
  font: normal normal medium 18px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.ms_links {
  width: 100%;
  display: flex;
  column-gap: 8px;
  margin-bottom: 30px;
}

.ms_assets_card {
  width: 300px;
  height: 135px;
  padding: 20px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #eae4f2;
  border-radius: 5px;
  opacity: 1;
}

.ms_card_upper_section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.ms_card_lower_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.ms_card_download_container {
  width: 36px;
  height: 36px;
  /* UI Properties */
  display: flex;

  justify-content: center;
  align-items: center;
  background: #ef0855 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;
}

.ms_assets_cards_container {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
}

.ms_card_p_wrapper {
  flex: 1;
  max-height: calc(6 * 1.2em);
  overflow-y: auto;
  margin-bottom: 10px;
}

.ms_card_p {
  width: 95%;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.zone_card_asset {
  border: 1px solid #eae4f2;
}

.assets_icons {
  font-size: 28px;
  margin-right: 4px;
}

.zone_card_download_btn {
  width: 36px;
  height: 36px;
}

.zone_card_links {
  font-size: 20px;
}

.brand_zone_logo_size {
  width: auto !important;
  height: auto !important;
  max-width: 100%;
  max-height: 100%;
}

.react-pdf__Page__svg {
  overflow: auto !important;
}

.modal_pdf_brand_zone_header {
  z-index: 200;
  position: relative;
}

.brand_zone_asset_modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand_zone_asset_modal .ant-modal {
  width: calc(100% - 100px) !important;
  height: 100% !important;
  max-height: calc(100% - 20px) !important;
  position: relative;
  text-align: center;
  top: 0px;
  overflow: hidden !important;
}

.brand_zone_asset_modal .ant-modal-content {
  height: 100%;
}

.brand_zone_asset_modal .ant-modal-close-x {
  width: 40px;
  height: 37px;
  line-height: 28px;
  font-size: 25px;
}

.fullscreen_brand_zone_asset_modal .ant-modal-close-x {
  width: 19px;
  height: 20px;
  line-height: 9px;
}
.fullscreen_brand_zone_asset_modal .ant-modal-content {
  height: 100vh !important;
}

.brand_zone_asset_modal .ant-modal .ant-modal-body {
  height: 100%;
  padding: 0px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.asset_modal_img_container {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  flex-wrap: wrap;
}

.modal_brand_zone_img {
  max-width: 100%;
  max-height: 550px;

  width: auto;
  height: auto;
}

.asset_modal_video_container {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  flex-wrap: wrap;
}

.modal_brand_zone_video {
  max-width: 100%;
  max-height: 550px;
  width: auto;
  height: auto;
}

.asset_modal_pdf_container {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  flex-wrap: wrap;
}

.asset_modal_pdf_header {
  position: absolute;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.smallscreent_pdf_document {
  overflow: auto;
  max-height: 90%;
  padding-top: 4px;
  margin-top: 30px;
  max-width: 100%;
}

.fullscreen_brand_zone_asset_modal .ant-modal .ant-modal-body {
  padding-top: 1px !important;
  height: 100%;
}

.fullscreen_brand_zone_asset_modal > .ant-modal {
  height: 100vh !important;
  width: 100vw !important;
  overflow: hidden;

  top: 0 !important;
  position: relative;
}

.fullscreen_pdf_document {
  overflow: auto;
  max-height: 850px;
  max-width: 884px;
}

.pdf_side_left_arrow {
  position: absolute;
  top: 330px;
  left: 30px;
}

.pdf_side_right_arrow {
  position: absolute;
  top: 330px;
  right: 30px;
}

.pdf_brand_zone_like {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.img_video_brand_zone_like {
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 5px;
}

.custom_border_asset {
  border: 1px solid #ef0855;
}

.asset_modal_web3d_container {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  height: 100%;
}

.asset_modal_web3d {
  width: 90%;
  height: 88%;
}

.chat_bot_custom_wrapper {
  bottom: 7vh;
}

@media (max-width: 1000px) {
  .ms_page_container {
    display: block;
    text-align: center;
  }
  .ms_img_container {
    width: 100%;
    height: 250px;
    border: 1px solid #cccccc;
    background-color: #cccccc;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
  }
  .ms_page_content {
    width: 100%;
  }
  .ms_btns_container {
    justify-content: center;
  }
  .ms_links {
    justify-content: center;
  }
}

@media (max-width: 800px) {
  .zone_card_asset {
    width: 100% !important;
  }

  .brand_zone_asset_modal .ant-modal {
    width: 100% !important;
    height: 80% !important;
  }
}

@media (max-width: 400px) {
  .brand_zone_logo_size {
    max-width: 100%;
    max-height: 100%;
  }
  .asset_modal_pdf_header {
    margin-top: 38px;
  }
  .asset_modal_web3d {
    width: 90%;
    height: 85%;
  }
}

@media (max-height: 800px) {
  .fullscreen_pdf_document {
    overflow: auto;
    max-height: 700px;
    max-width: 884px;
  }
}
@media (max-height: 722px) {
  .modal_brand_zone_video {
    height: 575px;
  }
}

@media (max-height: 700px) {
  .modal_brand_zone_img {
    max-height: 450px;
  }
  .fullscreen_pdf_document {
    overflow: auto;
    max-height: 550px;
    max-width: 884px;
  }
}

@media (max-height: 680px) {
  .smallscreent_pdf_document {
    overflow: auto;
    max-height: 475px;
    max-width: 100%;
  }

  .brand_zone_asset_modal .ant-modal {
    max-height: 610px !important;
  }

  .modal_brand_zone_video {
    height: 511px;
  }
}

@media (max-height: 610px) {
  .fullscreen_pdf_document {
    overflow: auto;
    max-height: 450px;
    max-width: 884px;
  }

  .smallscreent_pdf_document {
    overflow: auto;
    max-height: 424px;
    max-width: 100%;
  }
  .brand_zone_asset_modal .ant-modal {
    max-height: 550px !important;
  }
  .pdf_side_left_arrow {
    top: 270px;
  }

  .pdf_side_right_arrow {
    top: 270px;
  }
  .modal_brand_zone_img {
    height: 400px;
  }
  .modal_brand_zone_video {
    height: 433px;
  }
}
@media (max-height: 560px) {
  .brand_zone_asset_modal .ant-modal {
    max-height: 500px !important;
  }
  .pdf_side_left_arrow {
    top: 270px;
  }

  .pdf_side_right_arrow {
    top: 270px;
  }
  .modal_brand_zone_img {
    height: 370px;
  }
  .modal_brand_zone_video {
    height: 370px;
  }
}

@media (max-height: 500px) {
  .brand_zone_asset_modal .ant-modal {
    max-height: 400px !important;
  }
  .pdf_side_left_arrow {
    top: 270px;
  }

  .pdf_side_right_arrow {
    top: 270px;
  }
  .modal_brand_zone_img {
    height: 300px;
  }
  .modal_brand_zone_video {
    height: 325px;
  }
}
