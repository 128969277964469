.openGooglAuthModal {
  z-index: 99999;
}

.video-coneference-modal {
  overflow: hidden !important;
}
.video-coneference-modal > .ant-modal {
  height: 100vh !important;
  width: 95vw !important;
  overflow: hidden;

  top: 0.2% !important;
  position: relative;
}

.video-coneference-modal > .ant-modal > .ant-modal-content {
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  /* overflow-y: scroll; */
  position: relative;
}

.video-coneference-modal > .ant-modal > .ant-modal-content > .ant-modal-body {
  width: 100%;
  height: 100%;
  padding: 18px;
  padding-bottom: 2px;

  position: relative;
}

.video-conference-btns-container {
  display: flex;
  gap: 5px;
}

.video-conference-create-poll-btn {
  /* position: absolute;

  bottom: 40px;
  left: 43px; */
  width: 140px;
}

.video-conference-copy-btn {
  /* position: absolute;
  bottom: 40px;
  left: 86px; */
  width: 270px;
}

.video-conference-view-poll-btn {
  /* position: absolute;

  bottom: 40px;
  left: 65px; */
  width: 140px;
  /* margin-left: 10px; */
}

.video-conference-view-poll-btn-streaming {
  /* position: absolute;

  bottom: 40px;
  left: 183px; */
  width: 140px;
}

.video-conference-message-btn-streaming {
  /* position: absolute;

  bottom: 40px;
  left: 173px; */
  width: 140px;
}

.video-conference-hide-poll-btn {
  position: absolute;

  bottom: 48px;
  left: 80px;
}

.video-conference-result-poll-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-right: 155px;
}

.video-conference-result-poll {
  display: flex;
  gap: 10px;
  width: 50%;
}

.video-conference-result-poll span {
  width: auto;
  white-space: nowrap;
}
.video-coneference-modal
  > .ant-modal
  > .ant-modal-content
  > .ant-modal-close
  > .ant-modal-close-x {
  width: 28px !important;
  line-height: 13px !important;
}

.video-conference-poll-answers {
  display: flex;
  flex-direction: column;
}

.video-conference-bottom-btns {
  display: flex;
  justify-content: space-between;
}

.video-conference-create-poll-container {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
}

.video-conference-poll-answers-btn {
  width: 150px;
  border-radius: 10px !important;
}

.video-conference-poll-answers-switch {
  width: 20px;
}

.video-conference-result-chart-type {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-conference-create-poll-fields {
  display: flex;
  gap: 10px;

  margin-bottom: 7px 0px;
}

.video-conference-create-poll-label {
  width: 200px;
  text-align: end;
}

.video-conference-create-poll-question-poll {
  width: 60%;
}

.video-conference-create-poll-answers-container {
  display: flex;
  margin-left: 142px;
  gap: 5px;
  flex-wrap: wrap;
  overflow-y: auto;
  height: 14vh;
}

.video-conference-create-poll-answers-fields {
  display: flex;
  gap: 10px;
}

.video-conference-create-poll-answer-label {
  text-align: end;
}

/* .video-conference-create-poll-answer-input {
} */

.video-conference-create-poll-chart-type-select {
  width: 20%;
}

.video-conference-show-poll-container {
  padding-bottom: 7px;
  overflow-y: auto;
  height: 35vh;
  /* margin-left: 71px; */
}

/* .video-conference-result-poll-container {
  width: 50%;
} */

.video-conference-show-poll-list-chart-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 33%; */
  height: auto;
  padding-left: 48px;
  margin-bottom: 8px;
}

.video-conference-show-poll-list-chart-container-streaming {
  display: flex;
  justify-content: space-between;

  height: 262px;
  padding-left: 48px;
  margin-bottom: 8px;
}

.video-conference-show-poll-fields {
  padding: 15px 0;
}

.video-conference-show-poll-fields-streaming {
  padding-top: 45px;

  padding-left: 53px;
}

.video-conference-show-poll-chart-select {
  width: 200px;
  margin-right: 10px;
}

.video-conference-table-name {
  margin-left: 34%;
}

.video-conference-table-name-with-no-shuffle {
  margin-left: 45%;
}

.video-conference-btns-container-streaming {
  display: flex;
  gap: 5px;
}

.shuffle_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 75vh;
  width: 100% !important;
  z-index: 999999;
  width: 100%;
  left: 0;
  flex-direction: column;
  animation: blink 1.5s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 1500px) {
  /* .video-coneference-modal > .ant-modal > .ant-modal-content {
    overflow: auto;
  } */
}
