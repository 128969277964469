.new_asset_model .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #ef0855;
}

.select-text-dark {
  color: #000 !important;
}

.select-text-dark:hover {
  color: #ffffff !important;
}

/* To increase the width of video */
.player-wrapper {
  width: auto;
  height: auto;
}

.react-player-class {
  position: relative;
}

.react-player-class > div {
  position: absolute;
}

/* Event Theme Color Css */

.eventtheme_antd_input_style {
  width: 200px;
  padding: 2px;
  display: flex;
  align-items: center;
}

.eventtheme_input_style {
  width: 50px;
  padding: 5px;
}

/* .eventtheme_input_style span > input[type="color"] {
  padding: 0;
  margin: -2px;
  width: 36px;
  height: 25px;
  border: 0.25px solid #000000 !important;
} */

.eventtheme_antd_input_style_hex_code {
  text-align: center;
  border: none !important;
}
.eventtheme_padding_left {
  padding-left: 14.7%;
}

/* Event Settings Css */

.eventsettings_padding_left {
  padding-left: 14.7%;
}

.eventsettings_table tr {
  height: 65px;
}

.eventsettings_fileuload_style {
  width: 150px;
}

.eventsettings_row_grey {
  background-color: #fbfbfb;
}
.row_background {
  background-color: #fbfbfb;
  padding: 10px 0;
}

.event_input_color_container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.event_input_color_angle {
  width: 50px;
  height: 38px;
}

.button_color_container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.button_color_lable {
  width: 196px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.button_color_fields {
  display: flex;
  align-items: center;
  gap: 20px;
}

.uploaded_file_name_event_setting {
  max-width: 150px;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.edit_delete_section {
  /* position: relative;
  right: 0%; */
  margin-left: auto !important;
  margin-right: 0 !important;
  align-items: center;
}

.edit_delete_icon {
  color: black;
}
.edit_delete_icon:hover {
  color: #ef0855;
}

.multiple_vista_parent_div {
  height: 300px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.event_settings_increment_width {
  width: 110px;
}

.social_wall_repost_input_tablet {
  padding-left: 23px;
  padding-right: 0px;
}

.wall-graphics-modal .ant-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_background_theme_input {
  width: 215px;
}

.subdomain_text_wrapper {
  cursor: pointer;
}

@media (max-width: 1200px) {
  .button_color_lable {
    justify-content: flex-start;
  }
  .button_color_container {
    flex-direction: column;
  }
  .event_steps {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .ant-steps-navigation .ant-steps-item-container {
    margin-left: 0px !important;
  }
}

@media (max-width: 900px) {
  .eventtheme_antd_input_style {
    width: 150px;
  }
}
