.ant-breadcrumb ol {
  padding-left: 0px;
}

.brand-calendar-wrapper {
  display: flex;
}

.brand-calendar-wrapper .search-container {
  width: 20%;
  padding: 15px;
  box-shadow: 0px 5px 10px #0000000d;
}

.calendar-container {
  font-size: 12px;
}

.brand-calender-search-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.today-container {
  padding: 20px;
  border-left: solid 1px #eae4f2;
}

.calendar-container .MuiFormControl-root button,
.calendar-container .MuiFormControl-root label {
  color: transparent;
}

.calendar-container .MuiFormControl-root input {
  padding: 8px;
  position: relative;
}

.calendar-container .MuiFormControl-root .MuiInputAdornment-root {
  position: absolute;
}

.calendar-container .MuiFormControl-root button {
  width: 150px;
  border-radius: 0;
}

.calendar-container .MuiFormControl-root fieldset {
  border: none;
}

.calendar-header {
  display: flex;
  border: solid 1px #eae4f2;
}

.calendar-data {
  overflow-y: scroll;
  height: 65vh;
  scrollbar-width: thin;
}

.calendar-data::-webkit-scrollbar {
  width: 2px;
  height: 8px;
}

.calendar-header .calendar-header-item {
  min-width: 75px;
  text-align: center;
  border-right: solid 1px #eae4f2;
}

.calendar-header .time-header {
  box-shadow: 0px 5px 10px #0000000d;
}

.text-color {
  color: #ef0855 !important;
}

.zone-title {
  font-size: 10px;
  padding-top: 5px;
  background-color: #fbfbfb;
}

.header-title {
  font-size: 10px;
  padding-top: 5px;
}

.zone-container {
  display: flex;
  color: #ef0855;
  font-weight: 500;
  background-color: #fbfbfb;
}

.calendar-row {
  display: flex;
  min-height: 75px;
}

.empty-row {
  min-height: 30px;
}

.time-element {
  font-size: 11px;
  min-width: 75px;
  text-align: center;
  border-right: solid 1px #eae4f2;
  margin-top: -10px;
}

.calendar-section {
  display: flex;
  min-width: 25px;
  border-bottom: solid 1px #eae4f2;
  position: relative;
}

.zone-item {
  position: relative;
  background-color: #fbfbfb;
  border-right: solid 1px #eae4f2;
}

.session-item {
  position: relative;
  border-right: solid 1px #eae4f2;
  width: 100%;
}

.event-card,
.event-card-left {
  background-color: #f45288;
  padding: 5px 5px 5px 5px;
  border-radius: 5px;
  height: 70px;
  width: 100%;
  float: left;
  text-align: left;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 10px;
  color: white;
  cursor: pointer;
  border: solid 1px white;
}

.event-card-right {
  float: right;
}

.event-card-left:hover,
.event-card-right:hover {
  width: 200px;
  z-index: 99;
}

.border {
  border-radius: 4px;
  background-color: white;
}

@media (max-height: 800px) {
  .calendar-data {
    height: 61vh;
  }
}

@media (max-height: 750px) {
  .calendar-data {
    height: 58vh;
  }
}

@media (max-height: 700px) {
  .calendar-data {
    height: 58vh;
  }
}

@media (max-height: 650px) {
  .calendar-data {
    height: 51vh;
  }
}

@media (max-height: 600px) {
  .calendar-data {
    height: 46vh;
  }
}

@media (max-height: 550px) {
  .calendar-data {
    height: 40vh;
  }
}
