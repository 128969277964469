.expo_item_wrapper {
  margin-top: 58px;
}

.near_by_user_name {
  display: inline-block;
  width: 7rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}
