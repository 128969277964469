.side_searchbar_div {
  /* background-color: #FFF1F5; */
  border: 1px solid #ffb2cc;
  border-radius: 5px;
  width: 95%;
  margin-left: 0.3rem;
}
.side_searchbar_div:hover {
  width: 95% !important;
  background-color: #fffefe !important;
  border-radius: 5px !important;
}
.search_bar {
  max-height: 90vh;
  overflow-x: scroll;
}
.search_bar::-webkit-scrollbar {
  display: none;
}
